@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .terminal-scrollbar::-webkit-scrollbar {
    @apply w-[.75rem];
  }

  .terminal-scrollbar::-webkit-scrollbar-track {
    @apply bg-nota-black/[.75];
  }

  .terminal-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-nota-gray/[.15] rounded-md transition-all border-[3px] 
      border-solid border-transparent bg-clip-content;
  }

  .terminal-link {
    @apply cursor-pointer font-bold text-nota-green hover:underline hover:text-nota-cyan;
  }

  .terminal-bold {
    @apply cursor-pointer font-bold text-nota-yellow hover:text-nota-cyan;
  }

  .terminal-title {
    @apply font-bold text-nota-cyan;
  }

  .terminal-error {
    @apply cursor-pointer font-bold text-nota-red hover:text-nota-cyan;
  }

  .terminal-secret {
    @apply cursor-pointer text-nota-blue hover:text-nota-cyan;
  }
}

.project-domains {
  @apply hover:underline hover:text-nota-cyan text-nota-red-dark cursor-pointer whitespace-nowrap font-bold;
}
.project-websites {
  @apply hover:underline hover:text-nota-cyan text-nota-green cursor-pointer whitespace-nowrap font-bold;
}
.project-socials {
  @apply hover:underline hover:text-nota-cyan text-nota-pink cursor-pointer whitespace-nowrap font-bold;
}
.project-minisites {
  @apply hover:underline hover:text-nota-cyan text-nota-yellow-dark cursor-pointer whitespace-nowrap font-bold;
}
